$(document).ready(function() {
    if($('.slideSet').length > 0){
        $('.slideSet').flickity({
            //options            
            cellAlign: 'left',
            wrapAround: true,
            groupCells: "100%",
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true, 
        });
    };

    if($('.testimonialSlider').length > 0){
        $('.testimonialSlider').flickity({
            cellAlign: 'center',
            wrapAround: false,
            groupCells: false,
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });
    };  
    
    if($('.groupSlider').length > 0){
        $('.groupSlider').flickity({
            cellAlign: 'center',
            wrapAround: false,
            groupCells: true,
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });
    };  
    
    if($('.logoSlider').length > 0){
        $('.logoSlider').flickity({
            //options            
            cellAlign: 'left',
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            autoPlay: true,
            autoPlay: 3000, // 3 seconds
            fullscreen: true,
            lazyLoad: 6,
        });
    };

    

    if($('.companyLogo').length > 0){
        $('.companyLogo').owlCarousel({
            margin:30,
            loop:true,
            autoWidth:true,
            items:4,
            nav:false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 500,
            autoplayHoverPause: true,
        })
    };   
})